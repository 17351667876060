import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/htmlParse';
import { Hero, Cta } from '../acf';
import './post.scss';

const BlogPost = ({ data, location }) => {
  const { wordpressPost: post, site } = data;
  if (!post) return null;
  const { title, content, yoast, categories, date, author, slug, featuredImage, excerpt } = post;
  const { title: siteTitle } = site.siteMetadata;
  let isCaseStudy = true;

  return (
    <Layout location={location}>
      <SEO
        title={`${
          yoast.metaTitle ?
          decodeEntities(yoast.metaTitle) :
          `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
      />
      <Hero backgroundImage={featuredImage} subheading="Blog" heading={title} blurb={false} isCaseStudy={isCaseStudy} />
      <section className="post-container">
        <div className="wrapper">
          <div className="post-content-body" dangerouslySetInnerHTML={{__html: content}} />
        </div>
      </section>
    </Layout>
  )
}

export default Previewable(BlogPost);

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    wordpressPost(id: { glob: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
      categories {
        name
        slug
      }
      author {
        name
        slug
      },
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
      featuredImage: featured_media {
        source_url
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 90, srcSetBreakpoints: [ 1200 ]) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      excerpt
    }
  }
`
